import { Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header";
const AboutPage = () => {
  return (
    <>
      <div id='google_translate_element'></div>
      <Helmet>
        <title>About Us | vKover</title>
      </Helmet>
      <Header isTransparent />
      <Container maxWidth='lg'>
        <Stack textAlign={"center"} mb={4}>
          <Typography variant='h1' fontSize={"28px"} mt={2} fontWeight='400'>
            About Vkover
          </Typography>
          <Typography
            color={"primary"}
            variant='subtitle1'
            fontSize={"32px"}
            mt={3}
          >
            "Insurance Simplified by Technology"
          </Typography>
          <Typography variant='body1' fontSize={"20px"} mb={2} mt={2}>
            vKover is the online insurance marketplace combining cutting edge
            technology with the expertise of real licesenced Agents to help
            people get the coverage they need to protect their family,property
            and finances with confidence.{" "}
          </Typography>
          <Typography variant='body1' fontSize={"20px"}>
            vKover.com is operated by Yella Insurance broking private limited,an
            IRDAI authorized broking company.We are committed to provide a fast
            and easy experience of buying insurance.
          </Typography>
        </Stack>
      </Container>
      <Footer />
    </>
  );
};

export default AboutPage;
